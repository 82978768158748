var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manager-container" },
    [
      _c(
        "div",
        { staticClass: "query-item button-bar" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", round: "" },
              on: {
                click: function ($event) {
                  return _vm.handleClearCacheClick()
                },
              },
            },
            [_vm._v(" Clear MPA Product Cache ")]
          ),
          _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
            ? _c(
                "el-button",
                {
                  attrs: { round: "", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleCreateProductClick()
                    },
                  },
                },
                [_vm._v(" Create Product ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-block" },
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.productList,
                "cell-style": _vm.CELL_STYLE,
                "header-cell-style": _vm.HEADER_CELL_STYLE,
                height: "100%",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "Name" },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("* "),
                        ]),
                        _vm._v("Name"),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          on: {
                            blur: function ($event) {
                              return _vm.handleEditProduct(
                                scope.$index,
                                (_vm.field = "name")
                              )
                            },
                          },
                          model: {
                            value: _vm.products[scope.$index].name,
                            callback: function ($$v) {
                              _vm.$set(_vm.products[scope.$index], "name", $$v)
                            },
                            expression: "products[scope.$index].name",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Oil Type" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { filterable: "" },
                            on: { change: () => {} },
                            model: {
                              value: _vm.products[scope.$index].oilType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.products[scope.$index],
                                  "oilType",
                                  $$v
                                )
                              },
                              expression: "products[scope.$index].oilType",
                            },
                          },
                          _vm._l(_vm.OIL_TYPE_OPTIONS, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Fuel Grade Type", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { filterable: "" },
                            on: {
                              change: function ($event) {
                                return _vm.handleEditProduct(scope.$index)
                              },
                            },
                            model: {
                              value: _vm.products[scope.$index].fuelGradeType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.products[scope.$index],
                                  "fuelGradeType",
                                  $$v
                                )
                              },
                              expression:
                                "products[scope.$index].fuelGradeType",
                            },
                          },
                          _vm._l(_vm.FUEL_GRADE_TYPE_OPTIONS, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "MPA Code" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { filterable: "", clearable: "" },
                            on: {
                              change: function ($event) {
                                return _vm.handleEditProduct(scope.$index)
                              },
                            },
                            model: {
                              value: _vm.products[scope.$index].mpaCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.products[scope.$index],
                                  "mpaCode",
                                  $$v
                                )
                              },
                              expression: "products[scope.$index].mpaCode",
                            },
                          },
                          _vm._l(_vm.mpaCodeList, function (code) {
                            return _c("el-option", {
                              key: code.fuelTypeCode,
                              attrs: {
                                label: code.fuelTypeCode
                                  ? `${code.fuelTypeCode} (${code.fuelTypeDescription})`
                                  : "Select",
                                value: code.fuelTypeCode,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Updated At", width: "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              scope.row.updatedAt
                                ? _vm
                                    .$moment(scope.row.updatedAt)
                                    .format("YYYY-MM-DD HH:mm:ss")
                                : "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("dialog-add-product", {
        attrs: {
          dialogAddProduct: _vm.dialogAddProduct,
          mpaCodeList: _vm.mpaCodeList,
          productSelected: _vm.productSelected,
        },
        on: {
          handleAddProductDialogClose: function ($event) {
            _vm.dialogAddProduct = false
          },
          handleAddEditProductSuccess: _vm.handleAddEditProductSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }